:root {
  --codereGreen: #79c000;
}

.accordion {
  --bs-accordion-border-color: unset;
}

.imgFsDiv {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mt-3-6 {
  margin-top: 3.6rem !important;
}

.mt-6 {
  margin-top: 4rem !important;
}

.PageH1TitleSeoPages923,
.PageH2TitleSeoPages923,
.PageH3TitleSeoPages923 {
  font-weight: 400;
}

.PageH1TitleSeoPages923 {
  color: var(--codereGreen);
  text-align: center;
}

.PageH2TitleSeoPages923 {
  color: var(--codereGreen);
  text-align: left;
}

.PageH3TitleSeoPages923 {
  color: var(--codereGreen);
  text-align: left;
}

.wrapperEventosDeportivasLinkBtn {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.eventosDeportivasLinkBtnMy78 {
  text-decoration: none;
  padding: 0.5vw 2vw;
  background-color: var(--codereGreen);
  color: #fff;
  font-weight: 500;
  border-radius: 3px;
}

.eventosDeportivasLinkBtnMy78:hover {
  color: #fff;
}

.eventosDeportivasLinkTitle {
  text-decoration: none;
}

.eventosDeportivasLinkTitle > h2 {
  color: var(--codereGreen);
  text-align: center;
  font-size: 1.3vw;
}

.eventosDeportivasText {
  text-align: center;
}

.bottomContentPromoButton {
  -webkit-animation: glowingSEO 1.5s infinite;
  animation: glowingSEO 1.5s infinite;
  background: linear-gradient(180deg, #0587f5, #0057a0) !important;
  border: 0.15vw solid #e3e3e3a1 !important;
  border-radius: 70px;
  color: #fff;
  cursor: pointer;
  font-family: sans-serif;
  font-size: 1.3vw;
  font-weight: 700;
  margin: 0 auto;
  padding: 0.5vw 0;
  text-align: center;
  width: 14vw;
}

.text982T {
  color: #fff;

  /* font-size: 1.3rem; */
}

.text982T > a {
  text-decoration: none;
  color: var(--codereGreen);
}

.text982T > a:hover {
  text-decoration: none;
  color: var(--codereGreen);
}

.text982T > a:visited {
  text-decoration: none;
  color: var(--codereGreen);
}

.accordion {
  background-color: #252a30 !important;
}

/* Content Accordion */
/* #acc-section {
  width: 70%;
  margin: auto;
} */

#acc-section h2 {
  font-size: 1.8rem;
}

.accordion-body {
  color: #fff;
}

.accordion-body ul {
  padding-left: 1rem;
}

.content-accordion .accordion-button:not(.collapsed),
.content-accordion .accordion-button {
  color: #79c000;
  background-color: unset;
}

.accordion-button:not(.collapsed),
.accordion-button {
  background-color: unset;
}

.accordion-header,
.accordion-item {
  background-color: unset;
}

.accordion-button:focus {
  border-color: unset;
  box-shadow: unset;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.hamb-accordion {
  color: #fff;
}

.hamb-accordion .accordion-item {
  border: unset;
  background-color: #323f48;
}

.hamb-accordion .accordion-button:not(.collapsed),
.hamb-accordion .accordion-button {
  color: #fff;
  font-size: 5vw;
  border-bottom: #fff 1px solid;
  border: unset;
  box-shadow: none;
}

.hamb-accordion .accordion-body {
  padding: 0;
}

.hamb-accordion .accordion-body li {
  padding-top: 0.25rem;
}

.hamb-accordion .accordion-body li a {
  width: 100%;
  display: block;
}

.kuku {
  color: #fff;
}

/* End Accordion */
@media only screen and (max-width: 767px) {
  .eventosDeportivasLinkTitle > h2 {
    font-size: 6vw;
  }

  .eventosDeportivasLinkBtnMy78 {
    padding: 3vw 10vw;
  }

  .bottomContentPromoButton {
    font-size: 6.3vw;
    width: 50vw;
  }

  .RegistrateMobBtn {
    margin-top: 2rem !important;
  }

  footer.nb-footer .footer-info-single ul li a {
    font-size: 0.9rem;
  }
}
