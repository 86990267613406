#game_svg {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  animation-name: sideToSide;
  animation-duration: 0.8s;
  margin-left: 0.2vw;
  position: relative;
  top: 1px;
}

@keyframes sideToSide {
  0% {
    transform: translateX(0.2rem);
  }

  50% {
    transform: translateX(-0.1rem);
  }

  100% {
    transform: translateX(0.2rem);
  }
}

.landscapeGame .logo {
  margin: auto;
  top: 8vw;
  left: 0;
  right: 0;
}

#desktop_games {
  text-align: center;
}

#desktop_games h2 {
  color: #fff;
  font-size: 1.4vw;
  color: #e8e5e5;
  font-weight: 700;
  margin-bottom: 1vw;
  letter-spacing: 1px;
  margin-top: 1vw;
}

/* #desktop_games h1:nth-child(1) {
  margin-top: 3vw;
} */

.portrait_container,
.landscape_container {
  display: inline-flex;
  grid-template-columns: auto auto 0;
  grid-gap: 3rem;

  /* padding: 0.25rem 0; */
}

.landscape_container .landscapeGame {
  margin: auto;
  width: 35vw;
}

.game img {
  width: 21vw;
}

.landscapeGameImage,
.game_logo {
  transition: 0.3s;
}

.landscape_container .landscapeGame:hover .landscapeGameImage {
  transform: scale(0.95);
}

.landscape_card {
  background-color: #26363f;
  border-radius: 40px;
  height: 24vw;
  padding: 1rem;
  position: relative;
  overflow-x: hidden;
  border-radius: 16px;
}

img.landscapeGameImage {
  position: absolute;
  width: 35vw;
  top: 0;
  left: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  -webkit-box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.78);
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.78);
}

img.game_logo {
  margin: auto;
  position: absolute;
  width: 50%;
  top: 7vw;
  z-index: 1;
  right: 2vw;
}

.top_games_conatiner .topGame:nth-child(1) .game_logo {
  width: 9vw;
}

.top_games_conatiner .topGame .game_logo {
  top: 10vw;
  left: 0;
  right: 0;
}

img.game_logo:hover {}
img.logo:hover {
  transform: scale(1.15);
  transition: transform 300ms ease-in-out;
}

.game_info {
  bottom: 8px;
  position: absolute;
  text-align: left;
  border-left: 4px #fff solid;
  border-radius: 1px;
  color: #fff;
  padding-left: 1vw;
  margin-left: 1vw;
}

.landscapeGameTitle {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
}

/* Botttom Games */
.landscape_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 80%;
  margin: auto;
  cursor: pointer;
}

.landscape_container h3 {
  margin-top: 6px;
  color: #fff;
  font-size: 1.3vw;
}

.landscape_box .game img {
  width: 22vw;
  border-radius: 15px;
}

.portrait_container .portrait_card {
  position: relative;
  min-width: 20vw;
  height: 27vw;
  box-shadow: inset 5px 5px 5px rgb(0 0 0 / 20%),
    inset -5px -5px 15px rgb(255 255 255 / 10%), 5px 5px 15px rgb(0 0 0 / 30%),
    -5px -5px 15px rgb(255 255 255 / 10%);
  border-radius: 15px;
  margin: 25px 0;
  transition: 0.5s;
}

.espanol .logo {
  top: 10px;
}
/* .mejores .logo {
  top: 50%;
} */

.nuevos .logo {
  bottom: 25px;
}

.logo img {
  max-width: 95%;
}

.portrait_container .portrait_card .portrait_box {
  position: absolute;
  height: 25vw;
  top: -0.3vw;
  left: 15px;
  right: 15px;
  bottom: 0;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

.portrait_container .portrait_card:hover .portrait_box {
  transform: scale(0.9) translateY(-3vw);
}

.portrait_container .portrait_card .portrait_box .portrait_content {
  padding: 10px;
  text-align: center;
}

.portrait_container .portrait_card .portrait_box .portrait_content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

.portrait_container .portrait_card .portrait_box .portrait_content h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

.bottom_conatiner > a:nth-child(1) .logo,
.bottom_conatiner > a:nth-child(3) .logo {
  top: 3rem;
}

.bottom_conatiner > a:nth-child(2) .logo {
  top: 0.75rem;
}

.bottom_conatiner > a:nth-child(1) .logo img {
  height: 5vw;
}

.bottom_conatiner > a:nth-child(2) .logo img {
  height: 8vw;
}

.bottom_conatiner > a:nth-child(3) .logo img {
  height: auto;
}

.bottom_box .logo {}
.logo {
  transition: transform 300ms ease-in-out;
  position: absolute;
  z-index: 1;
}

.portrait_container .portrait_card .portrait_box .portrait_content p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}

.portrait_content span {
  border-radius: 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  color: #fff;
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 60%;
  font-size: 1.2vw;
  position: relative;
  text-decoration: none;
  top: 21.5vw;
  transition: 0.5s;
  z-index: -1;
}

.portrait_game_title {
  position: relative;
  color: #fff;
  font-size: 1rem;
  top: 22.2vw;
}

.portrait_container .portrait_card .portrait_box .landscape_content a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}

.landscape_content svg {
  top: 0;
  height: 0.8em;
  left: 5px;
}

.jugar_div {
  position: absolute;
  bottom: 12px;
  right: 10px;
}

.jugar_regis {
  width: 11vw;
  float: right;
  font-size: 1.2vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  border-radius: 40px;
}

.regis {
  border-radius: 30px;
  font-size: 0.95vw;
  padding: 0.4vw 1.3vw;
  margin: 0;
  margin-left: 0.5vw;
  font-weight: 600;
  display: block;
  height: 100%;
  background: linear-gradient(180deg, #0587f5, #0263b5);
}

.row {
  justify-content: center;
}

/* TEST */

.shiny {
  overflow: hidden;
}

.shiny::before {
  animation-name: slide;
  content: "";
  position: absolute;
  top: 0;
  left: -10px;
  width: 6vw;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  transform: skewX(155deg);

  /* transform: scale(1.2); */

  /* animation-name: slide; */
  animation-duration: 5s;
  animation-timing-function: ease-in-out;

  /* animation-delay: 2.2s; */
  animation-iteration-count: infinite;
  animation-direction: normal;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.03) 20%,
    rgba(176, 209, 240, 0.445) 100%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(61, 86, 201, 0.1) 30%
  );
  z-index: 1;
}

@keyframes slide {
  0% {
    left: -20vw;
    top: 0;
  }

  100% {
    left: 30vw;
    top: 0;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .landscape_card {
    height: 25vw;
  }
  .portrait_card {
    height: 28vw !important;
  }

  .landscapeGame .logo {
    /* top: 5vw; */
    height: 115px;
  }
  .portrait_game_title h3 {
    font-size: 1.1rem;
  }
}
